import React from "react"
import styled from "styled-components"
import { phone, desktop } from "../components/Style"
import { Box, Flex, radius } from "../components/Style"
import { secondaryTextOpacity, secondaryTextMargin } from "../components/Layout"

export default () => (
  <AcceptingStyle jcc aic>
    <Box align={"center"}>
      <Box scale={[1, 2]} mb={secondaryTextMargin} position={"relative"}>
        <Box
          position={"absolute"}
          top={"50%"}
          transform={{ x: "calc(-100% - 0.5em)", y: "-50%" }}
          w={0.35}
          h={0.35}
          r={radius.pill}
          bg={"#02FF24"}
        ></Box>
        <span>Accepting new projects</span>
      </Box>
      <Flex jcc scale={[0, 1]}>
        <Box o={secondaryTextOpacity} mr={0.5}>
          To
        </Box>
        <span>
          <a
            href="mailto:iamlinci@gmail.com?subject=hello"
            target="_blank"
            rel="noopener noreferrer"
          >
            iamlinci@gmail.com
          </a>
        </span>
      </Flex>
    </Box>
  </AcceptingStyle>
)

const AcceptingStyle = styled(Flex)`
  ${desktop`
    order: 2;
  `}
  ${phone`
    order: 10;
  `}
`
