import React from "react"
import styled from "styled-components"
import { phone, desktop } from "../components/Style"
import Case from "./Case"
import Accepting from "./Accepting"

import screenshotSoso from "../images/screenshotSoso.png"
import screenshotMiawang from "../images/screenshotMiawang.png"
import screenshotEzstudy from "../images/screenshotEzstudy.png"
import screenshotX11 from "../images/screenshotX11.png"
import screenshotBookmarks from "../images/screenshotBookmarks.png"
import screenshotSp from "../images/screenshotSp.png"

export default () => (
  <WorkGrid gap={10}>
    <Accepting />

    {workData.map((item, i) => (
      <Case data={item} key={i} i={i} />
    ))}
  </WorkGrid>
)

const WorkGrid = styled.div`
  width: 100vw;
  ${phone`
    padding: 1.5em;
    gap: 3em;
    grid-template-rows: repeat(6, 1fr);
  `}
  ${desktop`
    padding: 7.5vw 10vw 10vw;
    gap: 10vw;
    grid-template-columns: 1fr 1fr;
  `}

  display: grid;
`

// Data Section

const workData = [
  {
    cover: screenshotSoso,
    name: "SOSO Portfolio",
    link: "https://sosolin.me",
    tags: ["Design", "Code"],
    time: "2019",
    theme: { bg: "black", color: "#eee" },
    detail:
      'SOSO is a senior designer of Tencent. I helped him develop his portfolio, and I designed the unique page "Wall" to perfectly show his illustration works. This portfolio helped him find a satisfactory job : )',
  },
  // {
  //   cover: screenshotMiawang,
  //   name: "Mia Wang Portfolio",
  //   link: "https://miawang.me",
  //   tags: ["Code"],
  //   time: "2019",
  //   theme: { bg: "#F9DED4", color: "black" },
  //   detail:
  //     "Mia Wang is the lead designer of VUE. I develop her portfolio for showing her UI designs and illustrations works to sharing to her friends, as well as showing her abilities to the university she wants to apply.",
  // },
  // {
  //   cover: screenshotEzstudy,
  //   name: "EZ Study",
  //   link: "http://ezstudy.co/",
  //   tags: ["Prototype", "Design", "Code"],
  //   time: "2019",
  //   theme: { bg: "#FAE3BA", color: "black" },
  //   detail:
  //     "EZ Study is an overseas study institution. I am working with EZ Study from the prototype to online. The website is used to better present the professionalism and strength of EZ Study to students and their parents.",
  // },
  {
    cover: screenshotX11,
    name: "X11 color names",
    link: "https://x11.linci.co/",
    tags: ["Prototype", "Design", "Code"],
    time: "2020",
    theme: { bg: "black", color: "white" },
    detail: "A cheat sheet for fast building some projects.",
  },
  {
    cover: screenshotBookmarks,
    name: "Design Bookmarks",
    link: "https://bookmarks.linci.co/",
    tags: ["Prototype", "Design", "Code"],
    time: "2018",
    theme: { bg: "#F95259", color: "black" },
    detail:
      "Design bookmarks are my favorite of design inspires / tools / resources. The clean design helps me quickly find the inspiration I need in every day. Recommend digital designers to use it.",
  },
  {
    cover: screenshotSp,
    name: "Chinese Pinyin Trainer",
    link: "https://sp.linci.co/",
    tags: ["Design", "Code"],
    time: "2018",
    theme: { bg: "#FD9B54", color: "black" },
    detail:
      "双拼练习 is a Chinese input method practice tool to help who need to learn this input method. I try to eliminate the boring process. If you want to input Chinese faster, you should try it.",
  },
]
