import React from "react"
import { motion } from "framer-motion"
import { secondaryTextOpacity, secondaryTextMargin } from "../components/Layout"

import { Box, Flex } from "../components/Style"

export default ({ toggle, setToggle }) => {
  return (
    <>
      <Box
        z={100}
        overflow={"hidden"}
        position={"fixed"}
        top={0}
        left={0}
        right={0}
        w={"100vw"}
      >
        <Flex p={1.5} pb={[null, 3]} jcsb>
          <Box>
            Linci
            <Box as="span" o={secondaryTextOpacity} ml={secondaryTextMargin}>
              Designer, Coder
            </Box>
          </Box>
          <a
            href="https://twitter.com/lincidesign"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </Flex>

        <motion.div
          initial={false}
          animate={{ x: toggle ? 0 : "50vw" }}
          transition={{ duration: 0.6 }}
        >
          <Flex scale={4} my={[0.5, null]}>
            <NavItem
              onClick={() => {
                setToggle(false)
                window.scrollTo(0, 0)
              }}
              active={!toggle}
            >
              Work
            </NavItem>

            <NavItem
              onClick={() => {
                setToggle(true)
                window.scrollTo(0, 0)
              }}
              active={toggle}
            >
              Information
            </NavItem>
          </Flex>
        </motion.div>
      </Box>

      {/* Placeholder */}
      <div style={{ opacity: 0, pointerEvents: "none", userSelect: "none" }}>
        <Flex p={1.5} pb={[null, 3]}>
          Placeholder
        </Flex>
        <Flex scale={4} my={[0.5, null]}>
          Placeholder
        </Flex>
      </div>
    </>
  )
}

const NavItem = ({ children, active, onClick }) => (
  <Box w={"50vw"}>
    <Box
      display={"inline-block"}
      transform={{ x: "-50%" }}
      onClick={onClick}
      o={active ? 1 : secondaryTextOpacity}
      cursor={"pointer"}
    >
      {children}
    </Box>
  </Box>
)
