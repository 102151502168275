import React from "react"
import styled from "styled-components"
import SEO from "../components/SEO"
import { Box, Flex, color, radius } from "../components/Style"

import "../components/clear.css"

export default () => (
  <>
    <SEO title="404: Not found" />

    <Flex aic h={"100vh"}>
      <Box scale={3} ml={1.5}>
        <Box mb={0.5}>404</Box>

        <Box scale={0} color={color.gray}>
          I'm sorry , it looks like the page you're looking for must have been
          moved, deleted or doesn't exist.
          <Flex gap={1.5} mt={2}>
            <To href="https://linci.co">→ linci.co</To>
            <To href="https://bookmarks.linci.co" rel="noopener noreferrer">
              → Design Bookmarks
            </To>
            <To href="https://sp.linci.co" rel="noopener noreferrer">
              → 双拼练习
            </To>
          </Flex>
        </Box>
      </Box>
    </Flex>

    <style jsx="true" global="true">
      {`
        body {
          color: black;
          letter-spacing: 0.05em;
        }
        a {
          transition: color 400ms, opacity 400ms;
          text-decoration: none;
          color: black;
        }
        a:hover {
          opacity: 0.75;
        }
      `}
    </style>
  </>
)

const To = styled(Box).attrs({
  as: "a",
  b: color.gray5,
  p: 1,
  pr: 1.15,
  r: radius.pill,
})``
