import React from "react"
import { Box, Flex, Grid } from "../components/Style"
import { secondaryTextOpacity } from "./Layout"

export default () => (
  <Grid
    gap={[4, 8]}
    pt={[2, "5vw"]}
    pb={[4, "10vw"]}
    style={{ justifyContent: "center" }}
  >
    <InfoSection chapter={"Intro"}>
      <p>
        Hi, my name is Linci and I work as a digital designer in Shanghai. I
        have worked as a web designer for Software company, a UI designer for
        Cloud platform, and a product designer for Startups recently.
      </p>
      <p>
        My aim to find balance between simplicity, beauty and function in my
        work. I hope that the collaborators will get involved in the design from
        the prototype design stage, so that the design can be better integrated
        into the business of the collaborators. I have effectively self-taught
        in the field of Web, I can do design and code(React + CSS in JS) for web
        independently, and I just getting started with Swift UI for iOS.
      </p>
      <p>
        I am good at Web and App design, including Carding needs, UX
        design(wireframe, user flow …), Prototyping, UI design, Front-end
        development, your official website, or even your personal portfolio, I
        can help you. please contact me if you are interested.
      </p>
    </InfoSection>

    <InfoSection chapter={"Services"}>
      <p>Carding needs for your business</p>
      <p>Prototyping for your idea</p>
      <p>Design for Web / App / UI</p>
      <p>Develop with React</p>
    </InfoSection>

    <InfoSection chapter={"Contact"}>
      <a
        href="mailto:iamlinci@gmail.com?subject=hello"
        target="_blank"
        rel="noopener noreferrer"
      >
        iamlinci@gmail.com
      </a>
    </InfoSection>
  </Grid>
)

const InfoSection = ({ chapter, children }) => (
  <div>
    <Flex jcc>
      <Box
        mb={1.5}
        textTransform={"uppercase"}
        mx={"auto"}
        pb={0.75}
        bb={"hsla(0, 0%, 100%, 20%)"}
      >
        <Box o={secondaryTextOpacity}>{chapter}</Box>
      </Box>
    </Flex>

    <Flex
      aic
      column
      scale={[0, 1]}
      mx={[1.5, null]}
      paragraph
      maxWidth={[null, "40vw"]}
    >
      {children}
    </Flex>
  </div>
)
