import React, { useState } from "react"
import { color, Box, Flex, Icon } from "../components/Style"
import { rgba } from "polished"
import { secondaryTextOpacity, secondaryTextMargin } from "../components/Layout"

export default ({ data, i }) => {
  const [detailVisable, setDetailVisable] = useState(false)

  return (
    <Box scale={[0, 1]} style={{ order: (i + 1) * 2 - 1 }}>
      <Box pb={"62.5%"} bg={color.white} position={"relative"}>
        <a
          href={data.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ position: "absolute" }}
        >
          <img src={data.cover} alt={data.name} width="100%" height="auto" />
        </a>

        {detailVisable && (
          <Flex
            column
            jcsb
            position={"absolute"}
            top={[0, null]}
            left={[0, null]}
            right={0}
            bottom={0}
            bg={data.theme.bg}
            color={data.theme.color}
            p={1}
            pb={0}
            ml={[0, 7.5 * (200 / data.detail.length) + "vw"]}
          >
            <Box pb={0.75} paragraph>
              {data.detail}
            </Box>

            <Flex
              bt={rgba(data.theme.color, 0.2)}
              gap={secondaryTextMargin}
              scale={[-1, 0]}
              py={1}
            >
              {data.tags.map((item, i) => (
                <span key={i}>{item}</span>
              ))}
            </Flex>
          </Flex>
        )}
      </Box>

      <Flex mt={secondaryTextMargin} jcsb>
        <div>
          <Box mb={0.15}>{data.name}</Box>
          <Box o={secondaryTextOpacity}>{data.time}</Box>
        </div>

        <div
          onMouseEnter={() => setDetailVisable(true)}
          onMouseLeave={() => setDetailVisable(false)}
          role="button"
          tabIndex={0}
          style={{ cursor: "pointer" }}
        >
          {detailVisable ? (
            <Icon source={iconInfoActive} fill={data.theme.bg} />
          ) : (
            <Icon source={iconInfo} fill={color.black} />
          )}
        </div>
      </Flex>
    </Box>
  )
}

// SVG

const iconInfo = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 23.7539C18.4336 23.7539 23.7539 18.4336 23.7539 12C23.7539 5.56641 18.4336 0.246094 11.9883 0.246094C5.55469 0.246094 0.246094 5.56641 0.246094 12C0.246094 18.4336 5.56641 23.7539 12 23.7539ZM12 22.2422C6.32812 22.2422 1.76953 17.6719 1.76953 12C1.76953 6.32812 6.32812 1.76953 11.9883 1.76953C17.6602 1.76953 22.2305 6.32812 22.2422 12C22.2422 17.6719 17.6719 22.2422 12 22.2422ZM11.9297 7.57031C12.6562 7.57031 13.2188 7.00781 13.2188 6.29297C13.2188 5.57812 12.6562 5.00391 11.9297 5.00391C11.2148 5.00391 10.6406 5.57812 10.6406 6.29297C10.6406 7.00781 11.2148 7.57031 11.9297 7.57031ZM9.90234 18.3867H14.707C15.082 18.3867 15.375 18.1055 15.375 17.7422C15.375 17.3789 15.082 17.0859 14.707 17.0859H13.0195V10.5234C13.0195 10.043 12.7734 9.71484 12.3047 9.71484H10.0547C9.67969 9.71484 9.38672 10.0078 9.38672 10.3594C9.38672 10.7344 9.67969 11.0156 10.0547 11.0156H11.5898V17.0859H9.90234C9.52734 17.0859 9.23438 17.3789 9.23438 17.7422C9.23438 18.1055 9.52734 18.3867 9.90234 18.3867Z" />
  </svg>
)

const iconInfoActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 23.7539C18.4336 23.7539 23.7539 18.4336 23.7539 12C23.7539 5.56641 18.4336 0.246094 11.9883 0.246094C5.55469 0.246094 0.246094 5.56641 0.246094 12C0.246094 18.4336 5.56641 23.7539 12 23.7539ZM11.9297 7.57031C11.2148 7.57031 10.6406 7.00781 10.6406 6.29297C10.6406 5.57812 11.2148 5.00391 11.9297 5.00391C12.6562 5.00391 13.2188 5.57812 13.2188 6.29297C13.2188 7.00781 12.6562 7.57031 11.9297 7.57031ZM9.90234 18.3867C9.52734 18.3867 9.23438 18.1055 9.23438 17.7422C9.23438 17.3789 9.52734 17.0859 9.90234 17.0859H11.5898V11.0156H10.0547C9.67969 11.0156 9.38672 10.7344 9.38672 10.3594C9.38672 10.0078 9.67969 9.71484 10.0547 9.71484H12.3047C12.7734 9.71484 13.0195 10.043 13.0195 10.5234V17.0859H14.707C15.082 17.0859 15.375 17.3789 15.375 17.7422C15.375 18.1055 15.082 18.3867 14.707 18.3867H9.90234Z" />
  </svg>
)
