import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import "../components/clear.css"

import SEO from "../components/SEO"
import Nav from "../components/Nav"
import Work from "../components/Work"
import Infomation from "../components/Infomation"

export default () => {
  const [toggle, setToggle] = useState(false)

  return (
    <>
      <SEO title="" />

      <Nav toggle={toggle} setToggle={setToggle} />

      <AnimatePresence initial={false}>
        {!toggle && (
          <motion.div
            key="workMotion"
            initial={{ opacity: 0, x: "-2em" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "-2em" }}
            transition={{ duration: 0.6 }}
            style={{ position: "absolute" }}
          >
            <Work />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {toggle && (
          <motion.div
            key="infomationMotion"
            initial={{ opacity: 0, x: "2em" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "2em" }}
            transition={{ duration: 0.6 }}
            style={{ position: "absolute", width: "100%" }}
          >
            <Infomation />
          </motion.div>
        )}
      </AnimatePresence>

      <style jsx global>
        {`
          @import url("https://fonts.googleapis.com/css?family=Poppins:300,400&display=swap");

          body {
            font-family: "Poppins", SF Pro Text, -apple-system,
              BlinkMacSystemFont, Helvetica Neue, Helvetica, Roboto, Arial,
              PingFang SC, Hiragino Sans GB, Microsoft Yahei, Microsoft Jhenghei,
              sans-serif;
            font-weight: 300;
            transition: background 800ms, color 800ms;
            background-color: ${toggle ? "#181818" : "#f8f8f8"};
            color: ${toggle ? "white" : "black"};
            letter-spacing: 0.025em;
          }
          a {
            transition: color 400ms, opacity 400ms;
            color: ${toggle ? "white" : "black"};
            text-decoration: none;
          }
          a:hover {
            opacity: 0.75;
            text-decoration: underline;
          }
        `}
      </style>
    </>
  )
}
